import { render, staticRenderFns } from "./ItemAdminOKRs.vue?vue&type=template&id=5fa21bbe&scoped=true&"
import script from "./ItemAdminOKRs.vue?vue&type=script&lang=js&"
export * from "./ItemAdminOKRs.vue?vue&type=script&lang=js&"
import style0 from "./ItemAdminOKRs.vue?vue&type=style&index=0&id=5fa21bbe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fa21bbe",
  null
  
)

export default component.exports